<template>
    <v-gauge class="veecli-guage" :value="chartData.volAvailablePercentage" :options="opts" unit="%" width="150px" height="75px"/>    
</template>

<script>

import VGauge from 'vgauge';

export default {
    components: {
        VGauge
    },

    props: {
        chartData: {},
        height: Number,
        width: Number,
        fillColor: String

    },

    data() {
        return {
            opts: {
                angle: 0,
                lineWidth: 0.2,
                radiusScale: 1,
                // strokeColor: '#cc8033',
                colorStart: this.fillColor,   
                // colorStop: '#ffc34d',
                generateGradient: true,
                renderTicks: {
                    divisions: 4,
                    divWidth: 1.1,
                    divLength: 0.7,
                    divColor: '#333333',
                    subDivisions: 2,
                    subLength: 0.5,
                    subWidth: 0.6,
                    subColor: '#666666'
                },
                pointer: {
                    length: 0.47, // // Relative to gauge radius
                    strokeWidth: 0.03, // The thickness
                    color: '#e65c00' // Fill color
                },
            }
        };
    },

    mounted() {
        if (this.fillColor == "") {
            this.opts.colorStart = "#00b3b3"
        }
    }
}
</script>