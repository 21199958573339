<template>
    <b-navbar
        class='is-fixed-top is-dark sticky'>
        <template slot='brand'>
            <b-navbar-item v-if="user.name">
                <span class="open-button sidebarmenu" @click="$emit('toggle-side-bar')"><i class="fas fa-bars"></i></span>
            </b-navbar-item>
            <b-navbar-item>
                <div class="title has-text-white">
                    <img src="/images/veecli-log-with-name-small.png">
                    <div  v-if="!isProdSite" class="has-text-danger has-text-bold is-size-6 has-background-warning dev-site-warning">
                    TEST
                    </div>
                </div>              
            </b-navbar-item>

            <b-navbar-item dropdown class="is-hidden-desktop has-text-white has-text-weight-bold"  v-if='getActiveStore && isStoresMenuAllowed'>
                &nbsp;
                <div class="led-icon">
                    <b-tooltip position="is-bottom" class="is-info" multilined>
                        {{ getStoreDeviceStatus }}  
                        <template v-slot:content>
                            <div>Last update received at <br>{{ getDeviceLastHeartBeat |formatDateTime }}</div>
                        </template>
                    </b-tooltip>
                </div>
                &nbsp;
                <span class="mobile-storename">{{ getActiveStore.storeName.substr(0,20) }}</span>
            </b-navbar-item>

            <b-navbar-item  class="is-hidden-desktop has-background-dark" v-else-if="getUserName()">
                <a class="button is-primary has-text-white has-text-weight-bold" @click="addFirstStore">
                   <i class="fas fa-store-alt"></i> Add First Store
                </a>
            </b-navbar-item>     
        </template>
        <template slot='start'>
            <b-navbar-item class="has-background-dark has-text-white" >
                <router-link to="/"><span class="has-text-white">Home</span></router-link>
            </b-navbar-item>
            <b-navbar-item class="has-background-dark has-text-white" >
                <a href="https://veecli.com/features" target="_blank"><span class="has-text-white">Features</span></a>
            </b-navbar-item>

            <b-navbar-item class="has-background-dark has-text-white" >
                <a href="https://veecli.com/privacy" target="_blank"><span class="has-text-white">Privacy</span></a>
            </b-navbar-item>
        </template>
        
        <template slot='end' v-if='getUserName()'>
            <div class="led-icon" v-if="getActiveStore.storeName && isStoresMenuAllowed">
                <b-tooltip position="is-bottom" class="is-info" multilined>
                    {{ getStoreDeviceStatus }}  
                    <template v-slot:content>
                        <div>Last update received at <br>{{ getDeviceLastHeartBeat | formatDateTime  }}</div>
                    </template>
                </b-tooltip>
            </div>
            <b-navbar-dropdown 
                class="has-text-white" 
                :label="activeStoreLabel" 
                v-if='getActiveStore && isStoresMenuAllowed'>
                <b-navbar-item class="has-background-primary">
                    <router-link class="has-text-white has-text-weight-bold" to="/store/create">Add Store</router-link>
                </b-navbar-item>
                <b-navbar-item  class="has-background-dark" v-for="store in getInactiveStores.slice(0, 10)" v-bind:key='store.storeId'  @click="setActiveStore(store.storeId)">
                    <a class="has-text-white" href="#" @click="setActiveStore(store.storeId)">
                        <i class="fas fa-store"></i>&nbsp;{{ store.storeName }}
                    </a>
                </b-navbar-item>
                <b-navbar-item  v-if="getInactiveStores.length > 10" class="has-background-dark has-text-centered" @click='myStoresPage'>
                     <a class="has-text-info-light has-text-weight-bold has-text-right" href="#"  @click='myStoresPage'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;more stores ...</a>
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item  class="has-background-dark" v-if='!getActiveStore && isStoresMenuAllowed'>
                <a class="button is-primary has-text-white has-text-weight-bold" @click="addFirstStore">
                    Add First Store
                </a>
            </b-navbar-item>
            <b-navbar-item  class="has-background-dark"  v-if="!isStoresMenuAllowed">
                <a class="button is-primary has-text-white has-text-weight-bold" target="_new" href="https://veecli.com/contact-us/">
                    Request a Demo
                </a>
            </b-navbar-item>          

            <b-navbar-dropdown class="has-background-dark has-text-white" icon='person' icon-pack='fas' :label="getUserWithIcon">
                <b-navbar-item class="has-background-dark" href="#">
                     
                </b-navbar-item>
                <b-navbar-item class="has-background-dark" href="#" @click='myStoresPage'>
                     <a class="has-text-white has-text-weight-bold" @click='myStoresPage' href="#">
                     <i class="fas fa-store"></i>&nbsp;My Stores
                 </a>
                </b-navbar-item>
                <b-navbar-item class="has-background-dark" href="#" @click='profile'>
                     <a class="has-text-white has-text-weight-bold" href="#" @click='profile'><i class="fas fa-user-edit"></i> Edit Profile</a>
                </b-navbar-item>
                <b-navbar-item class="has-background-dark has-text-white" >
                <a href="https://veecli.com/video" class="has-text-white has-text-weight-bold" target="_blank"><i class="fas fa-video"></i> Video Tutorials</a>
                </b-navbar-item>                
                <b-navbar-item class="has-background-dark" href="#" @click='logout'>
                     <a class="has-text-white has-text-weight-bold" href="#" @click='logout'><i class="fas fa-sign-out-alt"></i> Logout</a>
                </b-navbar-item>
            </b-navbar-dropdown>

        </template>        
        <template slot='end' v-else>   
            <b-navbar-item  class="has-background-dark">
                  <router-link to="/login"><span class="has-text-white has-text-weight-bold" @click="login">Log in</span></router-link>
            </b-navbar-item>                     
            <b-navbar-item  class="has-background-dark">
                <router-link to="/signup" class="button is-primary has-text-white has-text-weight-bold" @click="signup">
                    <span class="has-text-white has-text-weight-bold" @click="signup">Sign up</span>
                </router-link>
            </b-navbar-item>
        </template>        
    </b-navbar>
</template>

<script>

import UserStore from "../../store/UserStore"

import router from '../app/../../router'

export default {

    props: {
      sideBarOn: Boolean,
      user: Object
    },

    data() {
        return {
            isScrollable: true,
            maxHeight: 10,
        }
    },

    methods: {

        login() {
             window.location.href = "/?login=true";
        },

        signup() {
             window.location.href = "/?signup=true";
        },

        myStoresPage() {
            router.push({ 
                path: '/user/storesmenu', 
            })
            .catch(() => true);            
        },

        profile() {
            router.push({ 
                path: '/user/edit', 
            })
            .catch(() => true);

        },

        logout() {
            UserStore.commit('userLogout')
        },

        setActiveStore(store_id) {
            UserStore.dispatch('setActiveStoreDetails', store_id)
        },

        addFirstStore() {
            router.push({ path: '/store/create' })
                .catch(() => true);
        }

    },

    computed: {

        isProdSite() {
            return window.location.hostname == 'app.veecli.com'
        },

        isPageLoading() {
            return UserStore.getters.getPageLoading
        },

        activeStoreLabel() {
            var storeName = this.getActiveStore.storeName
            var printName = ""
            if (storeName.length < 16) {
                printName = storeName
            } else {
                // printName = storeName.substr(0,11) + '...' + storeName.substring(storeName.length - 4)
                printName = storeName
            }
            return "⛽ " + printName 
        },

        // getAuth() {
        //     return UserStore.getters.isAuth
        // },

        // getUser() {
        //     return UserStore.getters.getUser
        // },

        // getUserName() {
        //     return UserStore.getters.getUserName
        // },

        getUserWithIcon() {
            return "👤 " + this.getUserName()
        },

        getActiveStore() {

            return UserStore.getters.getActiveStore
        },

        getInactiveStores() {

            // return getInactiveStores
            return UserStore.getters.getInactiveStoresSortedByName
        },

        getStoreRegisterPasswordExpireIn() {
 
            return UserStore.getters.getStoreRegisterPasswordExpireIn
        },

        getDeviceLastHeartBeat() {
            return new Date(UserStore.getters.getDeviceLastHeartBeat.lastHeartBeat*1000) 
        },

        getStoreDeviceStatus() {
            if (UserStore.getters.getStoreDeviceStatus == "OK") {
                return "🟢"
            } else {
                return "🔴"
            }
        }

    },
    
    mounted() {
       this.getActiveStore
    },

}
</script>

<style lang="scss">

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

// .navbar-item {
//     &.is-hoverable:hover {
        
//     }
// }
// .navbar-dropdown.store-menu {
//     // display: block;
//     height: 400px;
//     overflow: auto;
//     padding: 0px;
//     margin: -10px;
//     z-index: -1;
// }

// .navbar-dropdown {
//     display: block;
//     // height: 400px;
//     overflow: auto;
//     padding: 0px;
//     margin-top: -10px;
//     z-index: -1;
//     // background: none;
//     // background-color: #3c3c3c;
// }

// .scrollable-div {
//     background-color: #3c3c3c;
//     height: 400px;
//     overflow-y: auto;
// }


.badge-label {
    margin-top: 8px;
    font-weight: bold;
}

.badge-circle {
    margin: 1px;
    padding: 1px;
    font-size: 8px;
    // font-weight: bold;
}

.mobile-storename {

    font-size: 13px;
    // font-weight: bold;
}

.router-link-exact-active {
  background-color: #383822; 
}

.led-icon {
    display: flex;
        width: 20px;
        height: 5px;
        margin: auto;
        border-radius: 0px;
        border: 0px dashed #1c87c9;
        align-items:center;
        justify-content:center;
}

.dev-site-warning {
    position: absolute;
    width: 40px;
    margin-top: -25px;
    margin-left: 85px;
}


</style>