import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const LocalStore = new Vuex.Store({
    plugins: [createPersistedState({
		// paths: 'local',
		// key: 'stored',
		storage: window.localStorage
	})],
    
    state: {
        VeecliProductUpdateFormVersion: "0",
        VeecliDashboardVersion: "0",
        VeecliCalculatorsVersion: "0"
    },

    getters: {

        getCount: (state)  => {
            return state.count
        },

        getVeecliDashboardVersion(state) {
            return state.VeecliDashboardVersion
        },

        getVeecliProductUpdateFormVersion(state) {
            return state.VeecliProductUpdateFormVersion
        },

        getVeecliCalculatorsVersion(state) {
            return state.VeecliCalculatorsVersion
        },

    },

    mutations: {
        incrementCount(state) {
            state.count++
        },

        setVeecliDashboardVersion(state,ver) {
            state.VeecliDashboardVersion = ver
        },

        setVeecliProductUpdateFormVersion(state,ver) {
            state.VeecliProductUpdateFormVersion = ver
        },

        setVeecliCalculatorsVersion(state,ver) {
            state.VeecliCalculatorsVersion = ver
        },

    },
})


export default LocalStore;