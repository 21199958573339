<template>
<div class="has-text-weight-semibold">
    <b-loading v-model="isLoading" >
        <b-icon
            pack="fas"
            icon="spinner"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
    </b-loading>
    <div v-if="!isLoading">
        <div class="has-background-white has-text-weight-bold">
            <div class="columns">
                <div class="column title has-text-centered">{{ getActiveStore.storeName}}</div>
                <b-button class="intro-show-button" @click="forceIntro(introSteps)">
                    <b-icon
                        pack="fas"
                        icon="lightbulb"
                        type="is-warning"                    
                        size="is-small">
                    </b-icon>
                </b-button>                          
            </div>
        </div>
        <div class="columns">
                       
            <div class="column has-text-centered box" v-if="user.storeUsers.length == 0">
                <div class="is-size-4 has-text-info">
                    <p class="p-2"> Store data Available.</p>
                </div>
                <div>
                <p class="p-4">Thank you for signing up with VEECLi.</p> 
                <p class="p-4"><router-link class="button is-primary" to="/store/create">ADD YOUR FIRST STORE</router-link></p>
                <p class="p-4">Let us help you manage your store efficiently and accurately</p>
                </div>
            </div>
            <div class="column has-text-centered box" v-if="!getStore.verifoneSubscription && user.storeUsers.length > 0">
                <div class="is-size-4 has-text-info"> We are working on configuring the Store. </div><br>
                <div>Connectivity and data collection configuration from Verifone for the store is not complete. <br>Our onboarding expert will contact you if we need any additional information.  <br>
                If you don't hear from us within 24 hours, please contact support@veecli.com.  <br>
                Thank you for your patience.
                </div>
            </div>
        </div>
        <div class="columns" v-if="getStore.veederSubscription">
            <div class="column">
                <div class="has-text-weight-semibold">
                    <hr>
                    <div class="is-size-5 has-text-weight-bold has-text-centered">
                        <div class="columns">
                            <div class="column"></div>
                            <div class="column">
                                CURRENT FUEL INVENTORY
                            </div>
                            <div class="column" >
                                <router-link to="/store/alarm"  v-if="activeAlarmCount > 0">
                                    <b-tag class="pulsediv" type="is-danger"><span class="pulsediv">{{ activeAlarmCount }} Active Veeder Root Alarms!</span> </b-tag>
                                </router-link>
                                <!-- <a href="/store/alarm"><b-tag class="pulsediv" v-if="activeAlarmCount > 0" type="is-danger"><span class="pulsediv">{{ activeAlarmCount }} Active Veeder Root Alarms!</span> </b-tag> </a> -->
                            </div>                   
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column has-text-centered" v-for="item in tankInventory.tankInventoryList" :key="item.id">
                            <b-tooltip type="is-dark" :label="lastUpdated(item.lastModifiedDate)" position="is-bottom" :animated="true" dashed>
                                <article class="box">
                                    <p class="heading">Tank: {{ item.tankName }}</p>

                                    <guage
                                        :chartData="item"
                                        fillColor="#2db300"
                                    >
                                    </guage>
                                    <p class="is-size-4 has-text-weight-bold">{{ item.volume  }} gal </p>
                                    <p class="is-size-7 has-text-weight-light" v-if="item.lastModifiedDate">Updated {{  timeFromNow(item.lastModifiedDate) }}</p>

                                </article>
                        </b-tooltip>
                        </div>
                    </div>
                </div>            
            </div>
        </div>

        <div class="columns" v-if="getStore.verifoneSubscription && fuelPrices.length > 0">
            <div class="column">
                <div class=" has-text-weight-semibold">
                    <hr>
                    <div class=" is-size-5 has-text-weight-bold has-text-centered">
                        <div class="columns">
                            <div class="column">CURRENT FUEL PRICE</div>                   
                        </div>
                    </div>
                    <div class="columns ">
                        <div class="column has-text-centered" v-for="item in fuelPrices" :key="item.id">
                            <b-tooltip type="is-dark" :label="lastUpdated(item.lastModifiedDate)" position="is-bottom" :animated="true" dashed>
                                <article class="box">
                                    <p class="heading">{{ item.product }}</p>
                                    <p class="is-size-4 has-text-weight-bold" v-if="item.cashPrice">${{ item.cashPrice  }} <span class="price-label">Cash</span></p>
                                    <p class="is-size-4 has-text-weight-bold" v-if="item.creditPrice">${{ item.creditPrice  }} <span class="price-label">Credit</span></p>
                                    <p class="is-size-7 has-text-weight-light" v-if="item.lastModifiedDate">Updated {{ timeFromNow(item.lastModifiedDate) }}</p>
                                </article>
                            </b-tooltip>

                            
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column has-text-centered">
                            <a :href="gasBuddyURL" target="_gasbuddy">Gas prices in your city at Gas Buddy</a>
                        </div>
                    </div>
                </div>            
            </div>
        </div>

        <!-- <div class="columns" v-if="showRBOB && isProdSite"> -->
        <div class="columns" v-if="showRBOB">
            <div class="column has-text-centered">
                <article class="box">
                    <GasolinePrice 
                        :startDate="startDate"
                        :endDate="endDate"
                        fillColor="#2db300"
                        :chartHeight="200"
                        :chartWidth="100"
                        :options="chartOptions"
                    />
                </article>
            </div>
        </div>

        <div class="columns" v-if="getStore.verifoneSubscription && (getActiveStore.role == 'MANAGER' || getActiveStore.role == 'OWNER' || getActiveStore.role == 'ADMIN') && getStore.verifoneSubscription">
            <div class="column">
                <div class="has-text-weight-semibold">
                    <hr>
                    <div class=" is-size-5 has-text-weight-bold has-text-centered">
                        <div class="columns">
                            <div class="column is-4">

                            </div>
                            <div class="column is-4 is-uppercase">{{ monthName }} SALES</div>                   
                            <div class="column is-1">

                            </div>
                            <div class="column is-3 has-text-right">
                                <b-field  grouped>
                                    <b-datepicker
                                        @input="refreshChart"
                                        ref="monthSelector"
                                        type="month"
                                        v-model="month" 
                                        :mobile-native="false"
                                        icon="calendar"
                                        :min-date="minDate"
                                        :max-date = "maxDate"
                                        :editable="isMonthFieldEditable"
                                        :date-parser="monthParser"
                                        :date-formatter="monthFormatter"
                                        >
                                    </b-datepicker>
                                    <b-button
                                        @click="$refs.monthSelector.toggle()"
                                        icon-left="calendar"
                                        type="is-primary" />
                                </b-field>                                                        
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column has-text-centered">

                        </div>
                    </div>

                    <div class="columns">
                        <div class="column has-text-centered">
                            <article class="box">
                            <p class="heading">DEPARTMENT SALES</p>
                                <doughnut-chart 
                                    :width="chartWidth"
                                    :height="110" 
                                    :chart-data="monthDeptSales" 
                                    :options="doughnutOptions"
                                    >
                                </doughnut-chart>
                            </article>
                        </div>

                        <div class="column has-text-centered">
                            <article class="box">
                            <p class="heading">Merchandise Sales</p>
                                <bar-chart 
                                    :width="chartWidth"
                                    :height="chartHeight" 
                                    :chart-data="monthMerchSales" 
                                    :options="chartOptions"
                                    >
                                </bar-chart>
                            </article>
                        </div>

                        <div class="column">
                            <article class="box">
                            <p class="heading has-text-centered">Fuel Sales</p>
                                <bar-chart 
                                    :width="chartWidth"
                                    :height="chartHeight" 
                                    :chart-data="monthFuelSales" 
                                    :options="chartOptions"
                                    >
                                </bar-chart>
                            </article>
                        </div>

                        <div class="column">
                            <article class="box">
                            <p class="heading has-text-centered">Fuel Volume</p>
                                <line-chart 
                                    :width="chartWidth"
                                    :height="chartHeight" 
                                    :chart-data="monthFuelVolume" 
                                    :options="chartOptions"
                                    >
                                </line-chart>
                            </article>
                        </div>
                    </div> 

                    <div class="columns">
                        <div class="column  has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Net Sales</p>
                                <p class="is-size-4 has-text-weight-bold">{{ netSales | formatCurrency  }}</p>
                            </div>
                            </article>          
                        </div>

                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Merchandise Sales</p>
                                <p class="is-size-4 has-text-weight-bold">{{ merchSales  | formatCurrency }}</p>
                            </div>
                            </article>        
                        </div>

                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Fuel Sales</p>
                                <p class="is-size-4 has-text-weight-bold">{{ fuelSales  | formatCurrency }}</p>
                            </div>  
                            </article>           
                        </div> 

                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Fuel Volume</p>
                                <p class="is-size-4 has-text-weight-bold">{{ fuelVolume  | formatFuelVolume }}</p>
                            </div>
                            </article>             
                        </div> 
                    </div>

                    <div class="columns">
                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Payout</p>
                                <p class="is-size-4 has-text-weight-bold">{{ payOut | formatCurrency  }}</p>
                            </div>
                            </article>               
                        </div>

                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Cancel/Refund</p>
                                <p class="is-size-4 has-text-weight-bold">{{ cancelRefund  | formatCurrency }}</p>
                            </div>
                            </article>             
                        </div>

                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Void Line Amount</p>
                                <p class="is-size-4 has-text-weight-bold">{{ voidLineAmount  | formatCurrency }}</p>
                            </div>
                            </article>             
                        </div> 

                        <div class="column has-text-centered">
                            <article class="box">
                            <div>
                                <p class="heading">Discount</p>
                                <p class="is-size-4 has-text-weight-bold">{{ totalDiscounts  | formatCurrency }}</p>
                            </div>
                            </article>             
                        </div> 
                    </div> 
                </div>            
            </div>
        </div>    
    </div>
</div>
</template>

<script>

import UserStore from "../../../store/UserStore"

// import DataLoading from '../../../components/app/DataLoading'

import axios from 'axios'

import LineChart from './LineChart.js'
import BarChart from './BarChart.js'
import DoughnutChart from './DoughnutChart'
import GasolinePrice from './GasolinePriceGoBackEnd'
import Guage from '../../../components/app/Guage.vue'
import moment from 'moment'

export default {

    components: {
        BarChart,
        DoughnutChart,
        LineChart,
        Guage,
        GasolinePrice        
    },

    metaInfo: {
        title: 'Dashboard'
    },
    
    data() {
        return {
            // pageVersion: this.getAppVersion(),
            pageVersion: "1.0.4",
            pageName: "VeecliDashboard",
            api_url: '',
            showRBOB: true,
            isLoading: true,
            isFullPage: true,
            dataNotAvailable: false,
            isMonthFieldEditable: false,
            minDate: new Date(2020, 8, 1),
            maxDate: new Date(),
            user: [],
            pieData: [],
            barData: [],
            pieSelector: "#piechart",
            pieSize: 400,
            chartWidth: 100,
            chartHeight: 300,
            name_field: "time",
            value_field: "value",
            options: [],
            mopSalesChart: {},
            merchandiseChart: {},
            fuelVolumeChart: {},
            monthMerchSales: {},
            monthDeptSales: {},
            monthFuelSales: {},
            monthFuelVolume: {},
            tankInventory: {},
            activeAlarmCount: 0,
            netSales: 0.00,
            fuelSales: 0.00,
            merchSales: 0.00,
            totalDiscounts: 0.00,
            payOut: 0.00,
            voidLineAmount: 0.00,
            cancelRefund: 0.00,
            fuelVolume: 0.00,
            chartOptions: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
            doughnutOptions: {
                cutoutPercentage: 50,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                },
                scales: {
                    xAxes: [{ display: false }] // <-- add this
                }
            },
            guageOptions: {
                rotation: -1.0 * Math.PI,
                circumference: Math.PI,
                cutoutPercentage: 90,
                hover: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                },
                scales: {
                    xAxes: [{ display: false }] // <-- add this
                }

            },
            datasets: [],
            yaxislabels: [],
            fuelPrices: [],
            startDate: '',
            endDate: '',
            startDateText: '',
            endDateText: '',
            month: new Date(),
            guageData: [50,30,20],
            fuelPricesFromRapidApi: {},
            statesInCountry: [],
            storeStateFullName: '',
            gasBuddyURL: "https://www.gasbuddy.com/gasprices",
            introSteps: [
                {
                    title: 'Welcome to VEECLi Application',
                    intro: "NEW FEATURES<UL><LI>Break Even Price Calculator</LI><LI>Enhanced Product Price Update features</LI><LI>Fuel Inventory update, etc</LI></UL>"
                },
                {
                    title: 'Break Even Price Calculator',
                    intro: "Easily calculate your fuel break even price. Enter Fuel Cost and Retail Price you like to sell if for."
                },
            ]
        }
    },

    methods: {

        fetchTankInventory() {
            if (this.user.activeStore) {

                const url = this.api_url + 'stores/' + this.user.activeStore 
                            + '/tanks/status'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        if (response.data.data.tankInventoryList) {

                            this.tankInventory = response.data.data
                            UserStore.commit('setStoreDeviceStatus', this.tankInventory.connectivityStatus)

                        } else {
                            this.tankInventory = {}
                            this.dataNotAvailable = true
                        }

                    })
                    .catch( (err) => {
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            this.isLoading = false
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }

                    })
            }

        },

        fetchStoreWeekSales() {
            if (this.user.activeStore) {

                const url = this.api_url + 'stores/' + this.user.activeStore 
                            + '/dashboard'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        if (response.data.data.netSales) {

                            if (response.data.data.fuelVolume) {
                                // MOP Sales Chart
                                this.mopSalesChart = this.getChartData(response.data.data.netSales.netSalesValueList, "MOP Sales")
                            
                                // Merchandise Sale Chart
                                this.merchandiseChart = this.getChartData(response.data.data.netSales.merchSalesValueList, "Merchandise Sales")

                                // Fuel Volume Chart

                                this.fuelVolumeChart = this.getChartData(response.data.data.fuelVolume.fuelVolumeList, "Fuel Volume Sales")
 
                                // this.fuelPrices = response.data.data.fuelProducts

                                this.fuelPrices = []
                                response.data.data.fuelProducts.forEach(product => {
                                    if (product.cashPrice) {
                                        this.fuelPrices.push(product)
                                    }
                                })

                                this.activeAlarmCount = response.data.data.activeAlarmCount


                            } else {
                                this.fuelVolumeSales = [] 
                                this.dataNotAvailable = true
                            }
                            
                        } else {
                            this.dataNotAvailable = true
                        }
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        this.isLoading = false
                        if (err.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },

        fetchStoreMonthFuelSales() {
                
                this.monthFuelVolume = {
                    labels: [],
                    datasets: [],
                    options: []
                }

                const url = this.api_url + 'stores/' + this.user.activeStore 
                            + '/salessummary/fuel?mode=MONTHLY&fromDate=' + this.startDate + '&toDate=' + this.endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        var fuelVolumeSales = response.data.data.fuelTimeValueSeries

                        var fuelTypes = Object.keys(fuelVolumeSales)

                        var i;

                        var data = []
                        var labels = []
                        if (fuelTypes[0]) {
                            fuelVolumeSales[fuelTypes[0]].forEach( day => {

                                labels.push(moment(day.time).format("MM/DD/YYYY"))

                            })

                            this.datasets = []

                            for (i = 0; i < fuelTypes.length; i++) {


                                fuelVolumeSales[fuelTypes[i]].forEach( day => {

                                    data.push(day.value)

                                })

                                this.datasets.push({
                                    label: fuelTypes[i],
                                    data: data,
                                    fill: false,
                                    borderColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                                    backgroundColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),

                                })
                                data = []

                            } 

                            this.monthFuelVolume = {
                                labels: labels,
                                datasets: this.datasets,
                                options: this.chartOptions
                            }
                        }
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        this.isLoading = false
                        
                        if (err.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        
                    })
                

        },

        fetchStoreMonthMerchSales() {

            return new Promise((success, failed) => {
                if (this.user.activeStore) {
                    
                    const url = this.api_url + 'stores/' + this.user.activeStore 
                                + '/salessummary/overall?mode=MONTHLY&fromDate=' + this.startDate + '&toDate=' + this.endDate

                    axios.get(url,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken
                            },                   
                        })
                        .then( (response) => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data.data)

                            this.netSales = response.data.data.netSales
                            this.fuelSales = response.data.data.fuelSales
                            this.merchSales = response.data.data.merchSales
                            this.totalDiscounts = response.data.data.totalDiscounts
                            this.payOut = response.data.data.payOut
                            this.voidLineAmount = response.data.data.voidLineAmount
                            this.cancelRefund = response.data.data.cancelRefund
                            this.fuelVolume = response.data.data.fuelVolume

                            if (response.data.data.merchAndFuelSalesSummary) {

                                // Merchandise Sale Chart
                                this.monthMerchSales = this.getChartData(response.data.data.merchAndFuelSalesSummary.merchandize, "Merchandise Sales")

                                // console.log("Month Sales",this.monthMerchSales)

                                // Fuel Volume Chart
                                this.monthFuelSales = this.getChartData(response.data.data.merchAndFuelSalesSummary.fuel, "Fuel Volume Sales")

                            }
                            // success("Completed")
                            this.isLoading = false
                        })
                        .catch( (err) => {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                            
                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                            this.isLoading = false
                            failed("Failed")
                        })
                } else {
                        failed("No User")
                }
            })

                
        },

        fetchStoreMonthDeptSales() {
            if (this.user.activeStore) {

                const url = this.api_url + 'stores/' + this.user.activeStore 
                            + '/salessummary/department?mode=MONTHLY&fromDate=' + this.startDate + '&toDate=' + this.endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data.data)

                        if (response.data.data.departmentSalesSummaryList) {
                      
                            // Dept Sale Chart
                            this.monthDeptSales = this.getChartDeptData(response.data.data.departmentSalesSummaryList, "Department Sales")
                        }

                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        
                        if (err.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

                
        },


        fetchStatesInCountry() {
            if (this.user.activeStore) {

                const url = this.api_url + 'global/states/US' 

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data.data)

                        this.statesInCountry = response.data.data

                        var stateCode = UserStore.getters.getStore.state
                        var state = this.statesInCountry.find(state => state.code == stateCode)

                        if (state) {
                            var city = UserStore.getters.getActiveStore.city.toLowerCase().replace(" ","-")

                            this.gasBuddyURL = this.gasBuddyURL + "/" + state.name.toLowerCase().replace(" ","-") + "/" + city
                        } else {
                            this.gasBuddyURL = "https://www.gasbuddy.com/gasprices"
                        }
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        
                        if (err.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        this.statesInCountry = []
                        this.gasBuddyURL = "https://www.gasbuddy.com/gasprices"
                    })
            }
        },

        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        },

        getChartData(chartData, legend) {

            var labels = []
            this.datasets = []

            chartData.forEach(item => {

                // labels.push(item.time)
                labels.push(moment(item.time).format("MM/DD/YYYY"))

                this.datasets.push(item.value)

            })

            var chartObject = {
                labels: labels,
                datasets: [
                    {
                        label: legend,
                        data: this.datasets,
                        backgroundColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                    }
                ],
                options: this.chartOptions
            }
            // this.isLoading = false
            return chartObject

        },

        getChartDeptData(chartData, legend) {

            var labels = []
            var bgcolors = []
            this.datasets = []


            chartData.forEach(item => {

                labels.push(item.department)
                bgcolors.push('#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6))

                this.datasets.push(item.netSalesValue)

            })

            var chartObject = {
                labels: labels,
                datasets: [
                    {
                        label: legend,
                        data: this.datasets,
                        backgroundColor: bgcolors,
                    }
                ],
                options: this.doughnutOptions
            }
            
            return chartObject

        },

        getFuelChartData(percent, legend) {
            
            this.datasets = []            

            var bgcolors = ["#00ad00", "#c2c2c2"]

            var chartObject = {
                labels: [legend],
                datasets: [
                    {
                        data: [percent, 100-percent],
                        backgroundColor: bgcolors,
                    }
                ],
                options: this.guageOptions
            }
            
            // console.log(chartObject)

            return chartObject

        },

        async refreshChart() {

            this.isLoading = true
            this.startDate = (new Date(this.month.getFullYear(), this.month.getMonth(), 1)).toISOString().split('T')[0]                    
            this.endDate = (new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0)).toISOString().split('T')[0]

            UserStore.getters.getActiveStore.storeId
            if (UserStore.getters.getStore.veederSubscription) {

                this.fetchTankInventory()

            }

            if (UserStore.getters.getStore.verifoneSubscription && UserStore.getters.getActiveStore.role != 'STAFF') {
                await this.fetchStoreWeekSales()
                await this.fetchStoreMonthDeptSales()
                await this.fetchStoreMonthFuelSales()
                await this.fetchStoreMonthMerchSales()
                // this.isLoading = false
            } 
            
        },

        monthParser(date){
            console.log("Date",Date.parse(date))
            return moment(date).format('MMMM YYYY')
            // const date1 = new Date(date);  // 2009-11-10
            // const month = date1.toLocaleString('default', { month: 'long' });
            // return month
        }, 

        monthFormatter(date) {
            return moment(date).format('MMMM YYYY')
        },
        
        getFuelPricesFromRapidApi() {                
            var url = 'https://gas-price.p.rapidapi.com/stateUsaPrice'
            var params = {state: 'IL'}
            var headers = {
                    'X-RapidAPI-Key': '14b866326amsh47a70191252741ap1b343ajsn1b4b05ee253a',
                    'X-RapidAPI-Host': 'gas-price.p.rapidapi.com'
                }
            axios.get(
                url,
                params,
                headers
                )
            .then(function (response) {
                this.fuelPricesFromRapidApi = response.data
                console.log(this.fuelPricesFromRapidApi)
            })
            .catch(function (error) {
                console.error(error);
            });            
        },

        timeFromNow(time) {
            time=time.substring(0, 19)
            return moment(time).fromNow()
        },

        lastUpdated(time) {
            if (time) {
                time=time.substring(0, 19)
                return "Last Updated: " + moment(time).format("MM/DD/YYYY hh:mm:ss A")
            } else {
                return ""
            }
        },
    
    },

    computed: {

        isProdSite() {
            return window.location.hostname == 'app.veecli.com'
        },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        getStore() {
            return UserStore.getters.getStore
        },

        monthName() {
            // return moment(String(this.month)).format('MMMM YYYY')
            return moment(this.month).format('MMMM YYYY')
            // const date = new Date(this.month);  
            // const month = date.toLocaleString('default', { month: 'long' });

            // return month
        },

    },

    mounted() {
         
        if (process.env.VUE_APP_API_ROOT_URL.endsWith('/')) {
            this.api_url = process.env.VUE_APP_API_ROOT_URL
        } else {
            this.api_url = process.env.VUE_APP_API_ROOT_URL + '/'
        }
        
        if (this.month.getDate() == 1) {

            this.month.setMonth(this.month.getMonth() - 1)
        }
        this.user = this.getUser()

        if (UserStore.getters.getActiveStore.role == 'PLATFORM_FUEL_DATA') {
            this.$router.push('/platform/tanks')
        }
        // if (this.user.storeUsers.length == 0) {
        //     window.location.href = "/store/create"
        // }
        // this.getFuelPricesFromRapidApi()

        // this.gasBuddyURL = this.gasBuddyURL + "/" +
        //         UserStore.getters.getStore.state + "/" +
        //         UserStore.getters.getStore.city

        this.fetchStatesInCountry()
        this.refreshChart()
        this.isLoading = false   

        this.showIntro(this.pageName,this.pageVersion,this.introSteps)
    },

}
</script>

<style scoped>


/* .column {
    background-color:aliceblue;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 5px #888888;
} */

.heading {
  font-weight: 500;
  font-size: 14pt;
}

.price-label {
  font-weight: 300;
  font-size: 10pt;
}

.chart-container {
  flex-grow: 1;
  min-height: 0;
}

.chart-container  div {
    position: relative;
    height: 100%;
}

.box {
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}


.pulsediv {
	/* background: black;
	border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px; */

	/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 1); */
	transform: scale(1);
	animation: pulse 2s infinite;
    font-size: large;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(244, 61, 61, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 2px rgba(244, 61, 61, 0.7);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0   rgba(244, 61, 61, 0.7);
	}
}
</style>