<template>
  <div id="page-wrapper" class="page-wrapper chiller-theme toggled"  v-if="getActiveStore && sideBarOn && user.name && isLeftMenuAllowed">
  <nav id="sidebar" class="sidebar-wrapper">

    <div class="sidebar-content  has-text-weight-bold">
      <div class="sidebar-header">
          <!-- <div class="close-sidemenu has-text-right">
            <a id="close-sidebar" class="btn btn-sm btn-dark" href="#">
              <i class="fas fa-arrow-circle-left" v-if="sideBarOn && user.name" v-on:click="$emit('toggle-side-bar')"></i>
            </a>
          </div> -->
          <div class="sidebar-brand">
            <span class="has-text-grey-light is-uppercase has-text-weight-bold is-family-primary is-size-5 has-text-centered">
              {{ getActiveStore.storeName }}              
            </span>
          </div>

      </div>
      
      <!-- sidebar-header  -->
      <!--
      <div class="sidebar-search">
        <div>
          <div class="input-group">
            <input type="text" class="form-control search-menu" placeholder="Search...">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      -->
      <!-- sidebar-search  -->
        <div class="sidebar-menu">
          <ul>
            <b-collapse
              aria-id="contentIdForA11y2"
              class="panel"
              animation="slide"
              v-model="menuGeneralOpen">
              <template #trigger>
                  <li class="header-menu">
                    <span>GENERAL&nbsp;&nbsp;
                      <b-button class="intro-show-button-menu"  id="intro-show-button-icon" @click.stop="forceIntro(introGeneralSteps)">
                        <b-icon
                            pack="fas"
                            icon="lightbulb"
                            size="is-small">
                        </b-icon>
                      </b-button>
                      <!-- <b-tag type="is-danger" class="intro-show-button-menu"><b-icon
                            pack="fas"
                            icon="lightbulb"
                            size="is-small">
                        </b-icon></b-tag> -->
                    </span>                                        
                  </li>
              </template>
              
              <li class="sidebar" v-if="menuAvailable('dashboard')" id="intro-sidebar-dashboard">
                <router-link to="/">
                  <!-- <b-icon
                    pack="fas"
                    icon="chart-pie"
                    size="is-large"
                    custom-size="mdi-18px"
                    type="is-primary">
                  </b-icon> -->
                  <i class="fas fa-chart-pie"></i>
                  <span>Dashboard</span>
                  <!-- <span class="badge badge-pill badge-warning">New</span> -->
                </router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('calculators')" id="intro-sidebar-calculators">
                <router-link to="/calculators">
                  <i class="fas fa-calculator"></i>Calculators
                </router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('storesmenu')">
                <router-link to="/user/storesmenu" v-if="inactiveStores.length > 1">
                  <i class="fas fa-store"></i>My Stores
                </router-link>
              </li>
              <!-- <li class="sidebar" v-if="menuAvailable('mystoressummary')">
                <router-link to="/user/mystoressummary" v-if="inactiveStores.length > 1">
                  <i class="fas fa-store"></i>My Stores
                </router-link>
              </li> -->
            </b-collapse>

            <b-collapse
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuSalesOpen">
                <template #trigger>
                    <li class="header-menu" v-if="getStore.verifoneSubscription && menuAvailable('daily')">
                      <span>DATA ENTRY
                        <b-button class="intro-show-button-menu"  id="intro-show-button-icon" @click.stop="forceIntro(introDataEntrySteps)">
                        <b-icon
                            pack="fas"
                            icon="lightbulb"
                            size="is-small">
                        </b-icon>
                    </b-button>

                      </span>
                    </li>
                </template>
                <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('bankDeposits')" id="intro-sidebar-bankdeposits">
                  <router-link to="/store/deposit"><i class="far fa-building"></i>Bank Deposits</router-link>
                </li>                   
                <li class="sidebar" v-if="menuAvailable('creditCardCharges')" id="intro-sidebar-creditcardfees">
                  <router-link  to="/store/ccrecon"><i class="far fa-credit-card"></i>Credit Card Fees</router-link>
                </li>
                <!-- <li class="sidebar" v-if="menuAvailable('lotterySpotCheck')">
                  <router-link  to="/store/lotteryspotcheck"><i class="far fa-trophy"></i>Lottery Spot Check</router-link>  
                </li>                            -->
                <li class="sidebar" v-if="getSetting('showLotteryAudit') && menuAvailable('lotteryAudit')" id="intro-sidebar-lotteryaudit">
                  <router-link  to="/store/lottery/audit"><i class="fas fa-trophy"></i>Lottery Audit</router-link>  
                </li>
                <li class="sidebar" v-if="menuAvailable('monthEnd')" id="intro-sidebar-monthend">
                  <router-link :key="$route.fullPath" to="/store/monthend"><i class="fas fa-trophy"></i>Month End</router-link>  
                </li>
                <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('shift')" id="intro-sidebar-shift">
                  <router-link  to="/store/shift"><i class="fas fa-stream"></i>Shift</router-link>
                </li>                
            </b-collapse>

            <b-collapse
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuReportsOpen">
                <template #trigger>
                    <li class="header-menu" v-if="getStore.verifoneSubscription && menuAvailable('daily')">
                      <span>REPORTS
                        <b-button class="intro-show-button-menu"  id="intro-show-button-icon" @click.stop="forceIntro(introReportsSteps)">
                          <b-icon
                              pack="fas"
                              icon="lightbulb"
                              size="is-small">
                          </b-icon>
                        </b-button>
                      </span>
                    </li>
                </template>                         
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('shiftCalendar')">
                <router-link to="/store/reports/shiftcalendar"><i class="far fa-calendar-alt"></i>Calendar</router-link>
              </li>                
              <li class="sidebar" v-if="getSetting('showCommission') && menuAvailable('commissionReport') && getSettingUserAuthorized('commissionAuthorizedEmails')">
                <router-link to="/store/reports/commission"><i class="far fa-bell"></i>Commission</router-link>
              </li>
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('daily')">
                <router-link to="/store/sales"><i class="far fa-chart-bar"></i>Daily Sales</router-link>
              </li>
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('shiftReport')">
                <router-link to="/store/reports/shiftreport"><i class="far fa-bell"></i>Daily Cash Summary</router-link>
              </li>
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('departmentSales')">
                <router-link to="/store/department"><i class="far fa-building"></i>Department Sales</router-link>
              </li>
              <li class="sidebar" v-if="getSetting('showCreditDebitNetDeposit') && getStore.verifoneSubscription && menuAvailable('creditNetDeposit')">
                <router-link to="/store/ccdeposit"><i class="far fa-bell"></i>CC Net Deposit</router-link>
              </li>                 
              <li class="sidebar" v-if="menuAvailable('expenses')">
                <router-link to="/store/expenses"><i class="fas fa-receipt"></i>Expenses</router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('income')">
                <router-link to="/store/income"><i class="fas fa-receipt"></i>Income</router-link>
              </li>          
              <li class="sidebar" v-if="getSetting('trackInstantLotteryBooks') && menuAvailable('lottery')">
                <router-link to="/store/lottery"><i class="fas fa-dice"></i>Lottery</router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('lotterySales')">
                <router-link to="/store/lottery/sales"><i class="fas fa-dice"></i>Lottery Sales</router-link>
              </li>                 
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('accountantSummary')">
                <router-link to="/store/reports/accountantsummary"><i class="fas fa-book-open"></i>Accountant Summary</router-link>
              </li>
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('revenueSummaryReport')">
                <router-link to="/store/reports/revenuesummary"><i class="fas fa-balance-scale"></i>Revenue Summary</router-link>
              </li>
              <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('payrollReport') && getSettingUserAuthorized('payrollAuthorizedEmails')">
                <router-link to="/store/payroll"><i class="fas fa-building"></i>Payroll</router-link>
              </li>                                         
              <li class="sidebar" v-if="getStore.bankSubscription && menuAvailable('bankAccount') && getSettingUserAuthorized('bankAccountAuthorizedEmails')">
                <router-link to="/store/banks/accounts"><i class="fas fa-file-invoice-dollar"></i>Bank Accounts</router-link>
              </li>
            </b-collapse>

            <b-collapse
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuTankOpen">
                <template #trigger>
                    <li class="header-menu"  v-if="menuAvailable('tankMonitoring')">
                      <span>TANK MONITORING
                        <b-button class="intro-show-button-menu"  id="intro-show-button-icon" @click.stop="forceIntro(introTankMonitoringSteps)">
                          <b-icon
                              pack="fas"
                              icon="lightbulb"
                              size="is-small">
                          </b-icon>
                        </b-button>                        
                      </span>
                    </li>
                </template>
                <li class="sidebar" v-if="getStore.veederSubscription && menuAvailable('veederAlarm')">
                <router-link to="/store/alarm"><i class="far fa-bell"></i>Alarm</router-link>
              </li>          
              <li class="sidebar" v-if="getStore.veederSubscription && menuAvailable('fuelDelivery')">
                <router-link to="/store/delivery"><i class="fas fa-truck"></i>Delivery</router-link>
              </li>   
              <li class="sidebar" v-if="getStore.veederSubscription && menuAvailable('fuelInventory')">
                <router-link to="/store/dfir"><i class="fas fa-gas-pump"></i>Fuel Inventory</router-link>
              </li>   
              <li class="sidebar" v-if="getStore.veederSubscription && menuAvailable('leakDetection')">
                <router-link to="/store/leakreport"><i class="fas fa-flask"></i>Leak Detection</router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('platformTanks')">
                <router-link to="/platform/tanks"><i class="fas fa-thumbtack"></i>Tanks</router-link>
              </li>
            </b-collapse>

            <b-collapse
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuPriceBookOpen">
                <template #trigger>
                    <li class="header-menu" v-if="menuAvailable('priceBookHead') && (veecliSupportUser || getSettingUserAuthorized('priceBookAuthorizedEmails'))">
                      <span>PRICE BOOK
                        <b-button class="intro-show-button-menu"  id="intro-show-button-icon" @click.stop="forceIntro(introPriceBookSteps)">
                          <b-icon
                              pack="fas"
                              icon="lightbulb"
                              size="is-small">
                          </b-icon>
                        </b-button>
                      </span>
                    </li>
                </template>
                <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('productUpdates') && (veecliSupportUser || getSettingUserAuthorized('priceBookAuthorizedEmails'))">
                  <router-link to="/store/products/updates"><i class="fas fa-balance-scale"></i>Product Updates</router-link>
                </li>               
                <li class="sidebar" v-if="getStore.verifoneSubscription && menuAvailable('productList')">
                  <router-link to="/store/products/list"><i class="fas fa-balance-scale"></i>Products</router-link>
                </li>  
            </b-collapse>

            <b-collapse
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuSettingsOpen">
                <template #trigger>
                    <li class="header-menu" v-if="menuAvailable('settingsHead')">
                      <span>SETTINGS
                        <b-button class="intro-show-button-menu"  id="intro-show-button-icon" @click.stop="forceIntro(introSettingsSteps)">
                          <b-icon
                              pack="fas"
                              icon="lightbulb"
                              size="is-small">
                          </b-icon>
                        </b-button>                        
                      </span>
                    </li>
                </template>

              <li class="sidebar" v-if="menuAvailable('employees')">
                <router-link to="/store/employees"><i class="fas fa-user-friends"></i>Employees</router-link>
              </li>                
              <li class="sidebar" v-if="menuAvailable('expenseTypes')">
                <router-link to="/store/expenseTypes"><i class="fas fa-receipt"></i>Expense Types</router-link>
              </li> 
              <li class="sidebar" v-if="menuAvailable('departmentMappings') && getSettingUserAuthorized('profitMarginAuthorizedEmails')">
                <router-link to="/store/products/departments/mapping"><i class="fas fa-percent"></i>Profit Margin</router-link>
              </li>             
              <li class="sidebar" v-if="menuAvailable('storeSettings') && getSettingUserAuthorized('settingsAuthorizedEmails')">
                <router-link to="/store/settings"><i class="fas fa-store"></i>Store Settings</router-link>
              </li>                     

              <li class="sidebar" v-if="menuAvailable('storeUsers')">
                <router-link to="/store/users"><i class="fas fa-users"></i>Users</router-link>
              </li>                       
              <li class="sidebar" v-if="menuAvailable('vendors')">
                <router-link to="/store/vendors"><i class="fas fa-store-alt"></i>Vendors</router-link>
              </li>  

            </b-collapse>

            <b-collapse
                v-if="veecliSupportUser"
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuSupportOpen">
                <template #trigger>
                    <li class="header-menu" v-if="getStore.verifoneSubscription && menuAvailable('supportHead')">
                      <span>SUPPORT</span>
                    </li>
                </template>

                <li class="sidebar" v-if="menuAvailable('cashRegister') && veecliSupportUser">
                  <router-link to="/store/cashregister"><i class="fas fa-tag"></i>Cash Register            
                  <b-tooltip class="is-warning badge-tooltip" v-if="getStoreRegisterPasswordExpireIn > 0 && getStoreRegisterPasswordExpireIn < 10" label="Verifone Password Expiring Soon"
                      position="is-bottom"
                      :animated="true">

                      <b-tag class="button  badge-tooltip is-rounded is-warning">{{getStoreRegisterPasswordExpireIn}} Days</b-tag>
                  </b-tooltip>

                  <b-tooltip class="is-danger  badge-tooltip" v-if="getStoreRegisterPasswordExpireIn <= 0" label="Verifone Password Expired"
                      position="is-bottom"
                      :animated="true">
                      <b-tag class="button  badge-tooltip is-rounded is-danger">Expired {{Math.abs(getStoreRegisterPasswordExpireIn)}} days ago</b-tag>
                  </b-tooltip> 
                  </router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('clientConfig') && veecliSupportUser">
                <router-link to="/support/clientconfig"><i class="fas fa-store-alt"></i>Client Config</router-link>
              </li>  
              <li class="sidebar" v-if="menuAvailable('lotteryGames') && veecliSupportUser">
                <router-link to="/store/lottery/games"><i class="fas fa-store-alt"></i>Lottery Games</router-link>
              </li>                         
              <li class="sidebar" v-if="menuAvailable('editCompany') && veecliSupportUser">
                <router-link to="/company/edit"><i class="fas fa-building"></i>Update Company</router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('editStore') && veecliSupportUser">
                <router-link to="/store/edit"><i class="fas fa-store"></i>Store Update</router-link>
              </li> 
              <li class="sidebar" v-if="menuAvailable('subscriptionACH') && veecliSupportUser">
                <router-link to="/store/bank/verify"><i class="fas fa-money-check"></i>Store ACH Setup</router-link>
              </li>      
              <li class="sidebar" v-if="menuAvailable('storesStatus') && veecliSupportUser">
                <router-link to="/admin/storesstatus"><i class="fas fa-store-alt"></i>Stores Status</router-link>
              </li>
              <li class="sidebar" v-if="menuAvailable('remoteSupport') && veecliSupportUser">
                <router-link to="/support/remote"><i class="fas fa-store-alt"></i>Remote Support</router-link>
              </li>
            </b-collapse>
            <b-collapse
                aria-id="contentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="menuGeneralOpen">
                <template #trigger>
                    <li class="header-menu" v-if="getStore.verifoneSubscription && menuAvailable('daily')">
                      <span>SESSION</span>
                    </li>
                </template>
                <li class="header-menu" v-if="menuAvailable('logout')">
                  <a class="has-text-gray has-text-weight-bold" href="#" @click='logout'><i class="fas fa-sign-out-alt"></i> Logout</a>
                </li>
            </b-collapse>
          </ul>
        </div>
        <div class="has-text-centered">
          {{ getAppVersion() }}
        </div> 
      <div>
        
      </div>
      <hr>

    </div>

  </nav>
  <!-- sidebar-wrapper  -->
  <main class="page-content">

  </main>
  <!-- page-content" -->
</div>
<!-- page-wrapper -->
</template>

<script>

import UserStore from "../../store/UserStore"

export default {

    props: {
      sideBarOn: Boolean,
      user: {}
    },

    data() {
      return {
        pageVersion: "1.0.1",
        pageName: "VeecliSideBarMenu",
        introGeneralSteps: [
          {
            title: "Left Menu",
            element: "#intro-sidebar-menu1",
            intro: '<span class="introjs-heading-text">LEFT MENU </span><br>Options on the Left Menu gives you access to various options available for you in VEECLi.'
          },
          {
            title: "Left Menu: Dashboard",
            element: "#intro-sidebar-dashboard1",
            intro: '<span class="introjs-heading-text">GENERAL  ➩ Dashboard</span><br>The dashboard provides a comprehensive overview of your gas station operations, displaying key metrics such as fuel inventory levels, current fuel prices, sales figures for the month, and RBOB fuel price trends. This consolidated information enables efficient monitoring and decision-making to optimize performance and profitability.'
          },
          {
            title: 'Left Menu: Calculators',
            element: "#intro-sidebar-calculators1",
            intro: '<span class="introjs-heading-text">GENERAL ➩ Calculators </span><br>Calculators for Merchandise and Fuel Retail Price.'
          },
        ],
        introDataEntrySteps: [
        {
            title: 'Left Menu: Bank Deposits',
            element: "#intro-sidebar-bankdeposits1",
            intro: '<span class="introjs-heading-text">Data Entry ➩ Bank Deposits </span><br> This option allows to cross-check the deposit amount recorded during a shift with the actual deposit amount reflected in a bank account. There could be discrepancies between the deposit amount entered during a shift and the actual amount received by the bank. This verification process promotes transparency and facilitates auditing. It allows for quick identification and resolution of any discrepancies. This process can act as a safeguard against potential fraud or errors.'
          },
          {
            title: 'Left Menu: Credit Card Fees',
            element: "#intro-sidebar-creditcardfees1",
            intro: '<span class="introjs-heading-text">Data Entry ➩ Credit Card Fees </span><br>Once you receive the draft report or statement from your processor, navigate to the credit card fees menu option to input the credit/debit card fees. These fees will be recorded as expenses. Make sure to enter the details accurately to ensure your financial reports reflect the correct costs.'
          },
          {
            title: 'Left Menu: Month End',
            element: "#intro-sidebar-monthend1",
            intro: '<span class="introjs-heading-text">Data Entry ➩ Month End </span><br>Month-end option effectively calculates the net changes for the month and determine your business\'s net worth, start by entering the month-end values for physical inventory, assets, and liabilities. System will automatically calculate the sales, purchases and adjust with the previous month\'s balances for each category. This will give you a clear picture of your business\'s financial health and changes over the month.'
          },
          {
            title: 'Left Menu: Shift',
            element: "#intro-sidebar-shift1",
            intro: '<span class="introjs-heading-text">Data Entry ➩ Shift </span><br>Enter cash and other shift information on this page and calculate cash over/short. <br>&nbsp;</br>Sales information from your POS will be automatically collected. <br>&nbsp;</br>  Scan lottery tickets, enter lottery online sales and payouts, actual cash on hand,  expenses and inventory purchases, additional income, cigarette count, etc. to track your entire shift activities.'
          },
        ],
        introReportsSteps: [
          {
            title: 'Left Menu: Calendar',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Calendar </span><br>By organizing this data into a calendar layout, you can easily identify trends such as peak sales days, discrepancies in cash handling, and patterns in fuel versus merchandise sales. Analyzing these trends over time can help pinpoint areas for improvement, optimize inventory management, and enhance overall operational efficiency.'
          },
          {
            title: 'Left Menu: Commission',
            element: "#intro-sidebar-commission1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Commission </span><br>This option allows to calculate the commission for managers. Various options available in Store Settings to compute the commission.  This option will be available only to authorized users.'
          },
          {
            title: 'Left Menu: Daily Sales',
            element: "#intro-sidebar-dailysales1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Daily Sales </span><br>This report provides a comprehensive overview of sales performance for a specified month or date range, featuring a summary that aggregates total sales figures along with detailed daily breakdowns. Each day\'s sales data is presented, allowing for analysis of trends, peak periods, and overall performance, enabling stakeholders to make informed decisions based on the sales activity over the selected timeframe.'
          },
          {
            title: 'Left Menu: Daily Summary',
            element: "#intro-sidebar-dailysummary1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Daily Summary </span><br>This report provides a comprehensive overview of cash transactions, detailing receipts, expenses, and deposits while maintaining a running balance of cash on hand for the month. It organizes the data both by day and by shift, offering insights into daily cash flow and performance across different operational periods.'
          },
          {
            title: 'Left Menu: Department Sales',
            element: "#intro-sidebar-departmentsales1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Department Sales </span><br>The report offers detailed insights into sales metrics across different merchandise departments, highlighting trends, areas of growth, and potential challenges. It compares sales data across departments, identifies top-performing categories, and suggests strategies for improvement, enabling stakeholders to make informed decisions for optimizing overall sales performance.'
          },
          {
            title: 'Left Menu: CC Net Deposit',
            element: "#intro-sidebar-ccnetdeposit1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Daily Sales </span><br>This report shows a summary of Credit and Debit Card and fuel delivery. This helps to calculate the net deposit from the credit card processor.  This option is available only for authorized users.'
          },
          {
            title: 'Left Menu: Expenses',
            element: "#intro-sidebar-expenses1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Expenses </span><br>The expenses report provides a comprehensive overview of all expenses and inventory purchases, with the ability to filter by various criteria such as date, expense types, vendors, and inventory types. Additionally, it allows for updates to open checks or EFT payments once they are cleared from the bank, enabling accurate tracking of amounts due for payment.'
          },
          {
            title: 'Left Menu: Income',
            element: "#intro-sidebar-income1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Income </span><br>The income report provides a comprehensive overview of all additional income received.'
          },
          {
            title: 'Left Menu: Lottery',
            element: "#intro-sidebar-lottery1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Lottery </span><br>The lottery option offers a robust inventory management feature that allows you to seamlessly scan and track Instant Lottery books from the moment you receive them, monitoring their flow through various sales channels such as in-store sales, machines, and by shift. This system keeps you informed about the status of your inventory, including sold, returned, and settled items, ensuring you always have an accurate understanding of your Lottery inventory\'s value.'
          },
          {
            title: 'Left Menu: Accountant Summary',
            element: "#intro-sidebar-accountantysummary1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Accountant Summary </span><br>Use this report to send sales information to your accountant. You can customize what numbers are shown on the report in Store Settings.'
          },
          {
            title: 'Left Menu: Revenue Summary',
            element: "#intro-sidebar-revenuesummary1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Revenue Summary </span><br>This report provides a comprehensive overview of your business\'s financial performance, detailing total income generated, expenses incurred, net profit or loss, and expenditures on inventory purchases. It allows for analysis of cash flow, helping to identify trends in revenue and spending, along with areas for potential cost-cutting or investment. This summary is crucial for strategic decision-making and assessing the overall health of the business.'
          },
          {
            title: 'Left Menu: Payroll',
            element: "#intro-sidebar-payroll1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Payroll </span><br>Payroll report shows a summary of payroll by week. In the Store settings, authorized users can customize the payroll report by selecting the week beginning days, adjusting budget parameters, and modifying other relevant settings. Option only available for authorized users.'
          },
          {
            title: 'Left Menu: Bank Accounts',
            element: "#intro-sidebar-bankaccounts1",
            intro: '<span class="introjs-heading-text">REPORTS ➩ Bank Accounts </span><br>To access your bank account balance and transactions through the application, simply sign in to your bank using your credentials and authorize the connection. Once authorized, your data will be updated automatically, allowing you to monitor your banking activities seamlessly.'
          },
        ],
        introTankMonitoringSteps: [
          {
            title: 'Left Menu: Alarm',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">TANK MONITORING ➩ Alarm</span><br>The Veeder-Root system provides real-time monitoring of fuel management and environmental concerns by displaying both active and cleared alarms. Active alarms indicate current issues that require attention, while cleared alarms reflect previously addressed concerns, helping users to maintain compliance and ensure the optimal operation of their fueling systems. Users can utilize this information to respond promptly to potential problems and track historical data for analysis.'
          },
          {
            title: 'Left Menu: Delivery',
            element: "#intro-sidebar-dailysales1",
            intro: '<span class="introjs-heading-text">TANK MONITORING ➩ Delivery</span><br>This report shows actual fuel delivery data from Veeder-Root. These readings allow operators to monitor fuel flow, detect discrepancies, and ensure accurate inventory management, ultimately enhancing operational efficiency and reducing losses in fuel retail environments.'
          },
          {
            title: 'Left Menu: Fuel Inventory',
            element: "#intro-sidebar-dailysales1",
            intro: '<span class="introjs-heading-text">TANK MONITORING ➩ Fuel Inventory</span><br>The report provides a detailed account of daily fuel inventory for each product type, tracking stock levels, inflow, outflow, and discrepancies to ensure accurate reconciliation. This analysis aids in identifying any variances that may require further investigation, ensuring effective inventory management and operational efficiency.'
          },
          {
            title: 'Left Menu: Leak Detection',
            element: "#intro-sidebar-dailysales1",
            intro: '<span class="introjs-heading-text">TANK MONITORING ➩ Leak Detection</span><br>The report summarizes the fuel leak test results for tanks and sensors, ensuring compliance with safety regulations. It is essential that this report is generated and printed monthly to meet fire marshal requirements, demonstrating ongoing monitoring and maintenance of fuel systems to prevent potential hazards.'
          },
        ],
        introPriceBookSteps: [
          {
            title: 'Left Menu: Product Updates',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">PRODUCT ➩ Product Updates </span><br>Update merchandise prices easily by scanning the product barcode from anywhere and the system will update the POS. This allows staff to scan product barcodes and then quickly access price information to make real-time adjustments in the inventory management system, ensuring that all sales channels reflect the updated prices instantly. You can also view the product update history.'
          },
          {
            title: 'Left Menu: Daily Sales',
            element: "#intro-sidebar-dailysales1",
            intro: '<span class="introjs-heading-text">PRODUCT ➩ Products </span><br>This page provides a comprehensive overview of your merchandise in the POS system, allowing direct updates to costs and prices within the table. You can efficiently filter results for similar items and make quick changes, while additional details can be modified by clicking the edit button to access the product update form.'
          },
        ],
        introSettingsSteps: [
          {
            title: 'Left Menu: Employees',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">SETTINGS ➩ Employees </span><br>Create and update Employees. These employee information is used for shifts and payroll. You can create unlimited number of employees.'
          },
          {
            title: 'Left Menu: Expense Types',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">SETTINGS ➩ Expense Types </span><br>Expense Types allows you to add as many types of expenses you like to track.  If no expense types are showing, add add a type called \'Other\'.  The system will automatically populate all the default expense types. VEECLi provides most of the types often used.  You only need to add what is not already there.  Search and confirm before adding new expense types.'
          },
          {
            title: 'Left Menu: Profit Margin',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">SETTINGS ➩ Profit Margin </span><br>Profit margin for each department configured on your POS need to be set here for the system to calculate the cost of goods sold.  Also requires to identify each department to a group, for instance grocery, spirit, beer/wine, etc, so sales can be summarized and shown on various reports.'
          },
          {
            title: 'Left Menu: Store Settings',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">SETTINGS ➩ Store Settings </span><br>Store settings allows to update several store specific options. It also allows to control access to some of the features.'
          },
          {
            title: 'Left Menu: Users',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">SETTINGS ➩ Users </span><br>Authorize users to access your gas station information on VEECLi application.  Owners, Managers and Staff are few roles available and the access to features varies according to the role. Only users shown here is allowed to access the store.'
          },
          {
            title: 'Left Menu: Vendors',
            element: "#intro-sidebar-calendar1",
            intro: '<span class="introjs-heading-text">SETTINGS ➩ Vendors </span><br>Create and update Vendors. These include any company or person you purchase merchandise or fuel, pay for expenses, receive payment for additional income, etc.  These vendors will be available when posting expenses or additional income. You can create unlimited number of vendors.'
          },
        ],
        inactiveStores: UserStore.getters.getInactiveStores,
        menuGeneralOpen: true,
        menuSalesOpen: true,
        menuPriceBookOpen: false,
        menuSettingsOpen: false,
        menuTankOpen: false,
        menuReportsOpen: true,
        menuSupportOpen: false,
        optionsOnlyForSupportUsers: [
          'aby,thomas@veecli.com', 
          'saran.krishnan@veecli.com',
          'support@veecli.com',
          'aby@tsits.com'
        ],
        nonProdMenuItems: [
          "lotterySales"
          // "productList","productUpdates","lotterySales"
        ],
        menuRoles: [
            {"menuName": "dashboard", "roles": ["ADMIN","MANAGER", "OWNER"]},
            {"menuName": "calculators", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "storesmenu", "roles": ["ADMIN", "MANAGER", "OWNER","STAFF"]},
            {"menuName": "mystoressummary", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "sales", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "shift", "roles": [ "ADMIN", "OWNER", "MANAGER", "STAFF"]},
            {"menuName": "creditCardCharges", "roles": [ "ADMIN", "OWNER", "MANAGER" ]},
            {"menuName": "monthEnd", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "daily", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "creditNetDeposit", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "fuelPayments", "roles": [ "ADMIN", "OWNER", "MANAGER"]},            
            {"menuName": "departmentSales", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "bankDeposits", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "vendors", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "employees", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "expenses", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "expenseTypes", "roles": ["ADMIN", "OWNER", "MANAGER"]},            
            {"menuName": "income", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "payrollHead", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "payroll", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "inventoryHead", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "inventory", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "lottery", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "commissionReport", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "lotteryAudit", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "lotterySpotCheck", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "tankMonitoring", "roles": ["ADMIN", "OWNER", "MANAGER", "STAFF", "PLATFORM_FUEL_DATA"]},
            {"menuName": "veederAlarm", "roles": ["ADMIN", "OWNER", "MANAGER", "STAFF"]},
            {"menuName": "fuelDelivery", "roles": ["ADMIN", "OWNER", "MANAGER", "STAFF"]},            
            {"menuName": "fuelInventory", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "leakDetection", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "payrollReport", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "productList", "roles": ["ADMIN", "OWNER", "MANAGER","STAFF"]},
            {"menuName": "productUpdates", "roles": ["ADMIN", "OWNER", "MANAGER","STAFF"]},
            {"menuName": "bankAccount", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "reportsHead", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "accountantSummary", "roles": ["ADMIN", "OWNER", "MANAGER", "ACCOUNTANT"]},            
            {"menuName": "shiftCalendar", "roles": ["ADMIN", "MANAGER", "OWNER"]},
            {"menuName": "shiftReport", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "revenueSummaryReport", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "priceBookHead", "roles": ["ADMIN", "OWNER", "MANAGER", "STAFF"]},
            {"menuName": "productScan", "roles": ["ADMIN"]},
            {"menuName": "settingsHead", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "cashRegister", "roles": ["ADMIN"]},
            {"menuName": "departmentMappings", "roles": ["ADMIN", "OWNER", "MANAGER"]},
            {"menuName": "editStore", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "storeSettings", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "editCompany", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "storeUsers", "roles": ["ADMIN", "OWNER"]},
            {"menuName": "supportHead", "roles": ["ADMIN"]},
            {"menuName": "subscriptionACH", "roles": ["ADMIN"]},
            {"menuName": "registerBank", "roles": ["ADMIN"]},
            {"menuName": "storesStatus", "roles": ["ADMIN"]},
            {"menuName": "remoteSupport", "roles": ["ADMIN"]},
            {"menuName": "clientConfig", "roles": ["ADMIN"]},
            {"menuName": "lotteryGames", "roles": ["ADMIN"]}, 
            {"menuName": "lotterySales", "roles": ["ADMIN"]},           
            {"menuName": "platformTanks", "roles": ["ADMIN","PLATFORM_FUEL_DATA"]},
            {"menuName": "logout", "roles": ["OWNER", "ADMIN", "MANAGER", "ACCOUNTANT", "STAFF"]},             
        ],
      }
    },

    methods: {

        menuAvailable(menuItem) {

          var nonProdOnly = this.nonProdMenuItems.find( ({ menuName}) => menuName === menuItem)

          if (nonProdOnly && this.isProdSite) {
            return false
          }

          var menuRow = this.menuRoles.find( ({ menuName }) => menuName === menuItem )

          var roleFound = menuRow.roles.filter(item => (item === UserStore.getters.getActiveStore.role ))

          if (roleFound.length > 0) {
             return true
          } 

          return false
        },
      
        logout() {
            UserStore.commit('userLogout')
        },

    },

    computed: {
        isProdSite() {
            return window.location.hostname == 'app.veecli.com'
        },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        getStoreRegisterPasswordExpireIn() {
            return UserStore.getters.getStoreRegisterPasswordExpireIn
        },

        getStore() {

          return UserStore.getters.getStore

        },
        
        veecliSupportUser() {
        
          return this.optionsOnlyForSupportUsers.includes(this.getUser().email)
          
        },

    },

    mounted() {

    },


}
</script>

<style scoped>
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
body {
  font-size: 0.9rem;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 250px;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  padding-top: 30px;
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 2;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 4px 4px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .store-name {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 5px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 60px;
  display: flex;
}

.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 0;
}

.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
}

.page-wrapper .page-content > div {
  padding: 20px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #31353D;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #818896;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #b8bfce;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #16c7ff;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}

.chiller-theme .sidebar-footer {
    background: #3a3f48;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

#li {
  font-size: 50pc;
}

.badge1 {
   position:relative;
}
.badge1[data-badge]:after {
   content:attr(data-badge);
   position:absolute;
   top:-5px;
   right:-10px;
   font-size:.7em;
   background:rgb(235, 10, 10);
   color:white;
   width:18px;height:18px;
   text-align:center;
   line-height:18px;
   border-radius:50%;
   box-shadow:0 0 1px #333;
}

.router-link-exact-active {
  background-color:  rgb(83, 82, 82);
}

 nav li:hover,
 nav li.router-link-active,
 nav li.router-link-exact-active {
   background-color: rgb(134, 134, 134);
   cursor: pointer;
 }

</style>