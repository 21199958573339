<template>
    <div>

        <section id="header" class="hero is-fullheight page-sections">
        <div class="hero-body">
            <div class="container">
                
                <div class="row">
                    <p class="intro-heading has-text-centered">
                        VEECLi
                    </p>
                </div>
                <div class="row">
                    <p class="intro-lead-in has-text-centered">
                        State-of-the-art Cloud based <br>
                        Secure and Integrated
                    </p>
                    <p class="intro-description has-text-centered is-family-secondary">
                        Back office solution for 
                        gas stations and convenient stores.
                    </p>
                </div>

                <div class="row pt-6">
                  <b-button class="is-info mr-4 mt-4" @click="login">
                    Login
                  </b-button>
                  <b-button class="is-primary mt-4" @click="signup">
                    Sign up today! 30 Days Free Trial!
                  </b-button>
                </div>
            </div>
        </div>
        </section>

        <section id="secure" class="hero is-fullheight page-sections">
            <div class="hero-body">
                <div class="container">
                <p class="intro-heading has-text-centered">
                    Secure
                </p>
                <p class="intro-lead-in has-text-centered">
                    No need to open any firewall ports.
                </p>
                <p class="intro-description has-text-centered  is-family-secondary">
                    Protect your network and data from Internet
                </p>

                <div class="row pt-6">
                  <b-button class="is-info mr-4 mt-4" @click="login">
                    Login
                  </b-button>
                  <b-button class="is-primary mt-4" @click="signup">
                    Sign up today! 30 Days Free Trial!
                  </b-button>
                </div>
                </div>
            </div>
        </section>

        <section id="mobile" class="hero is-fullheight page-sections">
            <div class="hero-body">
                <div class="container">
                <p class="has-text-centered intro-heading">
                    Mobile Access
                </p>
                 <p class="intro-lead-in has-text-centered is-family-secondary">
                    It is your station.  See alarms, delivery, inventory, etc right on your iPhone or Android mobile device.
                </p>
                <div class="row pt-6">
                  <b-button class="is-info mr-4 mt-4" @click="login">
                    Login
                  </b-button>
                  <b-button class="is-primary mt-4" @click="signup">
                    Sign up today! 30 Days Free Trial!
                  </b-button>
                </div>
                </div>
            </div>
        </section>

        <section id="notify" class="hero is-fullheight page-sections">
            <div class="hero-body">
                <div class="container">
                <p class="has-text-centered intro-heading">
                    Notification
                </p>
                 <p class="intro-lead-in has-text-centered is-family-secondary">
                    Get notified about any alarms on your Mobile device.
                </p>
                <div class="row pt-6">
                  <b-button class="is-info mr-4 mt-4" @click="login">
                    Login
                  </b-button>
                  <b-button class="is-primary mt-4" @click="signup">
                    Sign up today! 30 Days Free Trial!
                  </b-button>
                </div>
                </div>
            </div>
        </section>       

        <section id="webaccess" class="hero is-fullheight page-sections">
            <div class="hero-body">
                <div class="container">
                <p class="has-text-centered intro-heading">
                    Web Access
                </p>
                 <p class="intro-lead-in has-text-centered">
                   All information also available on the web.
                </p>
                <div class="row pt-6">
                  <b-button class="is-info mr-4 mt-4" @click="login">
                    Login
                  </b-button>
                  <b-button class="is-primary mt-4" @click="signup">
                    Sign up today! 30 Days Free Trial!
                  </b-button>
                </div>
                <div class="has-text-black">
                  {{ userBrowser }}
                </div>
                <div class="has-text-black">
                  {{ appVersion }}
                </div>
                </div>
            </div>
        </section>


</div>

</template>

<script>

import UserStore from '../../../store/UserStore'
// import router from '../../../router'

export default {

  data() {
    return {
      appVersion: UserStore.getters.getAppVersion,
      userBrowser: UserStore.getters.getUserAgent
      // userBrowser: window.navigator.userAgent
    }
  },

  methods: {

    login() {
        window.location.href = "/?login=true";
    },

    signup() {
          window.location.href = "/?signup=true";
    },

  }
  
}
</script>

<style scoped>

.page-sections {
  background-repeat: no-repeat;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  color: white;
  height: 100vh;
  margin: 0;
}

#header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/images/header.jpg);
}

#secure {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/images/secure.jpg);
}

#mobile {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/images/mobile.jpg);
}

#notify {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/images/notify.jpg);
}

#webaccess {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/images/webaccess.jpg);
}

.intro-heading {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 25px;
  color: #9e68eb;
}


.intro-lead-in {
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 25px;
  color:#31a8bf;
}

.intro-description {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 25px;
  color: white;
}

@supports (-webkit-touch-callout: none) {
  .page-sections {
    background-attachment: scroll;
  }
}

@media (max-width: 768px) {

    .intro-heading {
      font-size: 40px;
    } 

    .intro-lead-in {
      font-size: 25px;
    }

    .intro-description {
      font-size: 20px;
    }    
    /* #secure {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(http://all4desktop.com/data_images/original/4236788-wallpaper-background.jpg);

    } */


};

/* @media (min-width: 768px){
  header .intro-text {
    padding-top: 300px;
    padding-bottom: 200px;
  }
  header .intro-lead-in {
    font-family: 'Raleway', sans-serif;    
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  header .intro-heading {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    line-height: 75px;
    letter-spacing: 12px;
  }
  header .intro-company {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: 12px;
  }  
} */

/*************************/
			/*OVERVIEW/*
/*************************/
/* #overview {
  z-index: 1000; 
}
.fa-stack {
  transition: transform 0.5s;
}

.fa-stack:hover {
  transform: scale(1.20);
  cursor: pointer;
}

section {
  padding: 100px 0;
}

section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}
section h3.section-subheading {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  text-transform: none;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 75px;
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

.thumbnail{
  position: relative;
  padding: 0;
  border: none;
} */

</style>