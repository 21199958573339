<template>
    <div class="outercontainer">
        <div class="outer">
            <p class="is-size-5">
                LAST 30 DAYS GASOLINE BASE PRICE (RBOB)
                <!-- <b-tooltip position="is-info" multilined>
                    <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                    <template v-slot:content>
                        <b>What is RBOB?</b><br>
                        {{ rbobDefinition }}.
                    </template>
                </b-tooltip> -->
                <tool-tip>
                    <div slot="title" class="help-tooltip-header">
                        What is RBOB?
                    </div>
                    <div slot="content" class="help-tooltip-content">
                        {{ rbobDefinition }}
                    </div>
                    <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                </tool-tip>
            </p>
            
            <line-chart 
                :width="chartWidth"
                :height="chartHeight" 
                :chart-data="priceHistory" 
                :options="chartOptions"
                >
            </line-chart>

            
        </div>
            <div class="inner">
                <div class="inner-content">
                    <b-tooltip type="is-dark" :label="lastUpdated" position="is-bottom" :animated="true" dashed>
                        <p class="is-size-5">LATEST PRICE</p>
                            <p class="is-size-4 has-text-weight-bold">{{ currentPrice  | formatCurrency }} </p>
                            <p class="is-size-6 has-text-weight-bold" :class="[changeAmount < 0.00 ? 'has-text-danger' : 'has-text-success']">
                            {{ changeAmount |formatCurrency }} 
                            <span v-if="isFinite(changePercent)">({{ changePercent }}%)</span>
                            <span v-else>(0.00%)</span></p>
                            <p class="is-size-7 has-text-weight-light">Updated {{  timeFromNow() }}</p>
                        
                    </b-tooltip>
                </div>
            </div>
        <div>
        <!-- <p class="has-text-weight-bold is-size-6">
            What is RBOB?
            <span class="has-text-weight-regular is-size-7 is-italic">{{ rbobDefinition }}</span>
        </p> -->
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import UserStore from "../../../store/UserStore"
import moment from 'moment'
import ToolTip from "../../../components/app/ToolTip"
import LineChart from './LineChart.js'

export default {
    props: {
        startDate: String,
        endDate: String,
        chartHeight: Number,
        chartWidth: Number,
        fillColor: String,
        options: []

    },

    components: {
        LineChart,
        ToolTip
    },

    data() {
        return {
            apiURL:"https://rbob.veecli.com/api",
            latestPrice: {},
            previousPrice: 0.00,
            priceHistory: {},
            priceFormatter: new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 4,      
                }),
            chartOptions: {
                scales: {
                    y: {
                        suggestedMin: 2,
                        suggestedMax: 5
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            latestPrices: {
                    labels: [],
                    datasets: [],
                    options: []
                },
            rbobDefinition: "Reformulated Blendstock for Oxygenate Blending (RBOB) gasoline is a fuel product made from refined crude oil. RBOB gasoline often goes by the names gasoline, petro gasoline or petrol."            
        }
    },

    methods: {
        fetchPriceHistory() {
            
            if (UserStore.getters.getActiveStore) {

                var url = this.apiURL + "/rbob/series/30" 

                axios.get(url,{
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                        },
                    })
                    .then( (response) => {
                        var result = response.data

                        if (Object.keys(result).length > 0) {
                            var labels = []
                            var price = []
                            var maxPrice = []
                            var minPrice = []

                            for (const [key, value] of Object.entries(result)) {
                                if (process.env.VUE_APP_DEBUG == 'true') console.log("Key",key)
                                if (process.env.VUE_APP_DEBUG == 'true') console.log("Value",value)
                                labels.push(moment(value.date).format("MM/DD/YYYY"))                            
                                price.push(value.price.toPrecision(6))
                                maxPrice.push(value.maxPrice.toPrecision(6))
                                minPrice.push(value.minPrice.toPrecision(6))
                            }
                            if (maxPrice.length > 1) {
                                this.previousPrice = maxPrice[maxPrice.length-2]
                            } else {
                                this.previousPrice = maxPrice[maxPrice.length-1]
                            }
                            
                            var datasets = [{
                                label: "Latest",
                                data: price,
                                fill: false,
                                borderColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                                backgroundColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                            },
                            {
                                label: "Max",
                                data: maxPrice,
                                fill: false,
                                borderColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                                backgroundColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                            },
                            {
                                label: "Min",
                                data: minPrice,
                                fill: false,
                                borderColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                                backgroundColor: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6),
                            }]
                            this.priceHistory = {
                                labels: labels,
                                datasets: datasets
                            }
                        }
                        

                    })
                    .catch( (err) => {
                        if (err.response) {
                            this.priceHistory = []
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                            this.isLoading = false
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }

                    })
            }

        },

        fetchPriceLatest() {
            
            if (UserStore.getters.getActiveStore) {
                
                var url = this.apiURL + "/rbob/latest?"

                axios.get(url)
                    .then( (response) => {
                        this.latestPrice = response.data
                    })
                    .catch( (err) => {
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                            this.isLoading = false
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                    })
            }

        },

        timeFromNow() {
            return moment(this.latestPrice.CreatedAt).fromNow()
        }
    },

    computed: {
        currentPrice() {
            return this.latestPrice.price
        },

        changeAmount() {
            return +(this.currentPrice - this.previousPrice).toFixed(4)
        },

        changePercent() {
            return +(this.changeAmount / this.previousPrice * 100).toFixed(4)
        },

        lastUpdated() {
            // return this.latestPrice.CreatedAt 
            return "Last Updated: " + moment(this.latestPrice.CreatedAt).format("MM/DD/YYYY hh:mm:ss A")
        }
    },

    mounted() {
        this.fetchPriceHistory()
        this.fetchPriceLatest()
    }
}
</script>

<style> 
    .outercontainer {
        display: grid;
    }
    .inner, .outer {
        grid-area: 1 / 1;
    }
    .inner {
        /* z-index: 1000; */
        background-color: rgba(246, 250, 225, 0.883);
        width: 150px;
        height: 120px;
        margin: 65px auto auto auto;
        padding-top: 5px;
        /* opacity: 80%; */
        box-shadow: 2px 2px rgb(93, 95, 95);
    }
</style>